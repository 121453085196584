.tooltip-card {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: max-content;
  }
  
  .tooltip-row {
    display: flex;
    justify-content: space-between;
  }
  
  .tooltip-title {
    margin-right: 5px;
    font-weight: 700;
    color: #5c5a5a;
  }
  
  .tooltip-value {
    text-align: right;
    font-weight: 300;
    color: white;
    margin-left: 40px;
  }

  .vertical-line {
    width: 1px;
    height: 80%;
    color: white;
    margin: 0 5px;
  }