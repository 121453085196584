.input-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .input-title {
    font-weight: bold;
    color: white;
    width: 150px;
    background: linear-gradient(to right, #141e30, #452455);
    border: none;
    background-size: 200% 100%;
    transition: background-position 0.5s;
    animation: gradientShift 5s infinite;
    border-radius: 5px;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    justify-content: center;
    padding: 10px 0px 10px 0px;
  }
  
  .units {
    color: #AFABAB;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .context-blue {
    color: #719AE9;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .context-purple {
    color: #BB5DF5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }