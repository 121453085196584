.stars-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .star {
    position: absolute;
    background: white;
    border-radius: 50%;
    animation: moveStar linear infinite;
  }
  
  @keyframes moveStar {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(1vw, 1vh);
    }
  }