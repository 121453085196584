.satellite {
    position: absolute;
    width: 80px;
    height: auto;
  }

  .satellite-image {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    .satellite {
      width: 60px;
    }
  }
  