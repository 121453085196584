@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.landing-page {
    height: 100vh;
    background: black;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .moon-image {
    width: 20%;
    height: auto;
    transform: rotate(-67.927deg);
    z-index: -1;
    align-items: center;
  }

  .landing-title {
    color: white;
    font-size: 4.5em;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(202, 202, 202, 0.8); /* Adjust the glow effect as needed */
  }

  .landing-subtitle {
    color: #b0b0b0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .explore-button {
    margin-top: 20px;
    color: white;
    width: 150px;
    height: 50px;
    background:  #452455 !important;
    border: none !important;
    background-size: 200% 100%;
    transition: background-position 0.5s;
    animation: gradientShift 5s infinite;
  }

  .explore-button:hover {
    background-color: #6a287d !important;
}

  .landing-button:hover {
    background-position: 100% 0%;
  }

  .satellite-container {
    position: absolute;
    top: 40%;
    right: 30%;
    width: 100px;
    height: auto;
    animation: satelliteAnimation 5s linear infinite;
  }
  
  .satellite {
    width: 100%;
    height: auto;
    transform-origin: top right;
  }

  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes satelliteAnimation {
    0% {
      transform: translate(0%, -100%) scaleX(-1);
    }
    100% {
      transform: translate(-1000%, 200%) scaleX(-1);
    }
  }

  @media (max-width: 768px) {
    .moon-image {
        width: 40%;
    }

    .landing-title {
        font-size: 3em;
    }

    .landing-subtitle {
        font-size: 18px;
    }

    .explore-button {
        width: 120px;
        height: 40px;
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .moon-image {
        width: 40%;
    }

    .landing-title {
        font-size: 2em;
    }

    .explore-button {
        width: 100px;
        height: 45px;
        font-size: 12px;
    }
}
