.input-field::-webkit-inner-spin-button,
.input-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-container{
    height: 100%;
}

.input-field{
    text-align: center;
    width: 150px !important;
    font-weight: 600 !important;
    color: #06050A !important;
    padding: 10px 0px 10px 0px !important;
    height: inherit;
}

.error {
    border: 2px solid #dc3545 !important; /* Red border for error */
    background-color: #f8d7da !important; /* Light red background for error */
    color: #dc3545 !important; /* Red text color for error */
    height: auto !important;
  }
  
  .error::placeholder {
    color: #dc3545 !important; /* Red placeholder text color for error */
  }

.text-danger {
    display: flex;
    width: 150px;
}