/* Base styles */
.main-row-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    /* margin-top: 50px !important; */
}

.satelliteInter-page {
    background: black;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: calc(100vh + 5%);
}

.left-panel {
    height: 100%;
}

.fov-frame {
    position: relative;
    width: 700px;
    height: 700px;
    border: 1px solid #FFF;
    background-color: rgba(21, 21, 21, 1);
    border-radius: 50%;
    margin-left: 10%;
}

.fov-title, .pill-title {
    color: white;
    margin-top: 10px;
    font-weight: 600;
    margin-left: 10%;
}

.search-button {
    margin-top: 20px;
    color: white;
    height: 50px;
    background-color: #452455 !important;
    border: none !important;
    transition: background-color 0.3s;
    animation: gradientShift 5s infinite;
    cursor: pointer;
}

.search-button:hover {
    background-color: #6a287d !important;
}

.search-button:active {
    background-color: #2e123e !important;
}

.search-button:disabled {
    background-color: #a0a0a0 !important;
    cursor: not-allowed;
}

.exposure-pill-container {
    width: 100%;
    height: 25px;
    background-color: #452455;
    position: relative;
    margin-top: 10px;
    margin-left: 10%;
    border-radius: 10px;
}

.exposure-pill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #612671;
    transition: width 0.3s;
    border-radius: 10px;
}

.blue-dot, .green-dot, .red-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
}

.blue-dot { background-color: #719AE9; }
.green-dot { background-color: #4CAF50; }
.red-dot { background-color: #F44336; }

.message-box {
    position: absolute;
    top: 49%;
    left: 52%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
}

.message {
    padding: 10px;
}

/* Media Queries */
@media (max-width: 1024px) {
    .satelliteInter-page {
        height: auto;
    }

    .fov-frame {
        width: 80vw; 
        margin-left: 10%;
    }
}

@media (max-width: 768px) {
    .satelliteInter-page {
        height: auto;
    }

    .main-row-container {
        flex-direction: column;
        text-align: center;
    } 

    .fov-title, .pill-title {
        margin-left: 0;
        margin-top: 20px !important;
        margin-bottom: 10px;
    }

    .exposure-pill-container {
        width: auto;
        margin-left: 0px;
    }

    .exposure-pill {
        width: auto;
    }

    .fov-frame {
        width: calc(100%);
        height: calc(100vw - 50px);
        margin-left: 0;
    }

    .search {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .search-button {
        margin-top: 20px;
        width: 80% !important;
    }

    .row {
        width: auto;
        margin-left: 0px;
        margin-right: 0px;
    }
}
